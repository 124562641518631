.ag-theme-alpine {
    --ag-foreground-color: rgb(46, 60, 132);
    --ag-background-color: rgb(255, 255, 255);
    --ag-header-foreground-color: rgb(255, 255, 255);
    --ag-header-background-color: rgb(0, 121, 194);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(0, 121, 194);

    --ag-font-size: 12px;
    /*!*--ag-font-family: monospace;*!*/

    --ag-grid-size: 4px;
    --ag-list-item-height: 13px;
    --ag-header-height: 30px;

    .ag-cell {
        border-right-color: #d5dae3;
        border-right-width: 1px;
        border-right-style: solid;
        padding-left: unset;
        padding-right: 4px;
        /*margin-left: unset;*/

    }

    .ag-header-cell, .ag-header-group-cell {
        padding-left: 4px;
        padding-right: unset;

    }

    .ag-header-cell {
        border-right: 1px solid #d2d7df;
    }

    /*--ag-border-color: red;*/
    /*--ag-secondary-border-color: green;*/
    /*--ag-row-border-color:pink;*/
    /*--ag-borders: solid 2px;*/
    /*--ag-border-color: red;*/
    /*--ag-borders-critical:solid 2px;*/
    /*--ag-borders-secondary:solid 2px;*/
    /*--ag-row-border-style: solid;*/
    /*--ag-row-border-width: solid;*/
    /*--ag-row-border-color: red;*/
    /*--ag-borders-input: solid 2px;*/

}


/*.ag-header-cell {*/
/*    border-right: 1px solid gray;*/
/*}*/


.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#myGrid {
    flex: 1 1 0px;
    width: 100%;
}

.number {
    text-align: right;
}

.ag-row-level-0 {
    font-weight: bold;
}

.ag-row-level-1 {
    color: darkblue;
}

.ag-row-level-2 {
    color: darkgreen;
}

