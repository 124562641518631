.Login {
    flex-direction: column;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login__Container {
    flex-direction: column;
    display: flex;
    gap: 10px;
}

.Login__Submit {
    margin-top: 20px;
}