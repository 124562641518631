.custom-tooltip {
    padding: 1px 10px;
    color: white;
    background-color: #0079c2;
    border-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.xlsImportModal {
        min-width: 500px;
    min-height: 150px;
    padding: 30px 50px;
    /*z-index: 100;*/
    height: auto;
}

.button__cancel__import {
    margin-left: 10px;
    background-color: #b90000;
    /*margin-right: 60px;*/
}