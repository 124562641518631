.modal_bsi {
    min-width: 700px;
    min-height: 250px;
    padding: 60px 50px;
    /*z-index: 100;*/
    height: auto;
}
.modal_diagram {
    min-width: 90%;
    min-height: 30%;
    padding: 60px 50px;
    /*z-index: 100;*/
    height: auto;
}

.iconTrash {
    margin-left: 10px;
}

.iconTrash:hover {
    color: red;
    cursor: pointer;
}

.modal .inputElement {
    margin-bottom: 20px;
}

.parentComponent {
    display: flex;
    justify-content: flex-end;
}

.cancel {
    margin-left: 10px;
    background-color: #b90000;
    margin-right: 50px;
    margin-top: 10px;
    /*right: 10px;*/
}

.Grid {
    width: unset;
}
