.modal_graph {
    min-height: 400px;
    width: 650px;
    padding: 20px;
    /*z-index: 100;*/
}

.modal .inputElement {
    margin-bottom: 20px;
}

.parentComponent {
    display: flex;
    justify-content: flex-end;
}

.button_graph___cancel {
    margin-left: 10px;
    background-color: #b90000;
}

.Grid {
    width: unset;
}
