.headerCmp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

}

.headerContainer {
    /*position: fixed;*/
  top: 0;
  left: 0;
  /*padding-bottom: 20px;*/
}