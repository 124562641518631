.modal {
    max-height: 400px;

    max-width: 500px;
    padding: 20px;
    /*z-index: 100;*/
}

.modal .inputElement {
    margin-bottom: 20px;
}

.parentComponent {
  display: flex;
  justify-content: flex-end;
}

.button__cancel {
  margin-left: 10px;
    background-color: #b90000;
}

.Grid {
    width: unset;
}
